<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner2">
      <!-- payment cards -->

      <v-card class="my-12 px-6 py-6">
        <v-card-title>
          <p class="mb-2 mb-sm-0">
            支払い情報
          </p>
        </v-card-title>
        <PaymentComponentVue></PaymentComponentVue>
      </v-card>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus, mdiDeleteOutline, mdiSquareEditOutline,
} from '@mdi/js'
import {
  ref,
} from 'vue'

import {
  mapState, mapActions,
} from 'vuex'

import PaymentComponentVue from './PaymentComponent.vue'

export default {
  components: {
    // PaymentCardDetail,
    PaymentComponentVue,
  },
  setup() {
    // const store = useStore()
    const paymentCards = ref([])
    const deleteLoading = ref(false)
    const isEdit = ref(false)
    const isEditAddressDialogVisible = ref(false)
    const cardDetail = ref({
      id: '',
      owner: '',
      cardNo: '',
      cardExpires: '',
      CVV: '',
    })
    const isPayEditOpen = ref(false)
    const isCardOpen = ref(false)
    const isPlanUpgradeDialogVisible = ref(false)
    const resolvePaymentStatusVariant = status => {
      if (status === 'Primary') return 'primary'
      if (status === 'Expired') return 'error'

      return 'secondary'
    }
    const editCardDetails = cardID => {
      const detail = paymentCards.value.filter(item => item.id === cardID)
      cardDetail.value = JSON.parse(JSON.stringify(detail[0]))
      Object.assign(cardDetail.value, {
        saveCardDetails: true,
      })

      if (isCardOpen.value) {
        isCardOpen.value = false
      } else {
        isCardOpen.value = true
      }
    }

    return {
      paymentCards,
      cardDetail,
      isPayEditOpen,
      isCardOpen,
      isEditAddressDialogVisible,
      deleteLoading,
      resolvePaymentStatusVariant,
      editCardDetails,
      isPlanUpgradeDialogVisible,
      isEdit,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiSquareEditOutline,
      },
    }
  },
  computed: {
    ...mapState('card', ['cardInfo']),
  },
  watch: {
    isCardOpen() {
      if (!this.isCardOpen) {
        this.refreshInfo()
      }
    },
  },

  created() {
    this.refreshInfo()
    this.loadGMO()
  },

  methods: {
    loadGMO() {
      if (document.getElementById('gmo_multipayment')) {
        return
      }
      const script = document.createElement('script')

      // script.src = 'https://static.mul-pay.jp/ext/js/token.js'
      script.src = process.env.VUE_APP_GMO_TOKEN_ENDPOINT

      script.id = 'gmo_multipayment'
      script.async = true
      script.onload = () => {
        // eslint-disable-next-line no-undef
        Multipayment.init(process.env.VUE_APP_GMO_SHOP_ID)
      }
      document.body.appendChild(script)
    },
    ...mapActions('card', ['loadCardInfo', 'deleteCard']),
    handleDeleteCard() {
      this.deleteLoading = true
      this.deleteCard()
        .then(() => {
          this.isEdit = false
          this.paymentCards = []
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.deleteLoading = false
        })
    },
    refreshInfo() {
      this.loadCardInfo().then(() => {
        const card = this.cardInfo
        if (card.length === 0) {
          this.paymentCards = []
          this.isEdit = false
        } else {
          this.paymentCards = [
            {
              id: card.cardSeq,

              // cardImg: 'master.png',
              // imgWidth: '45',
              owner: card.holderName,
              cardNo: card.cardNo,
              cardExpires: `${card.expire.slice(0, 2)}/${card.expire.slice(2)}`,

              // cardStatus: 'Primary',
            },
          ]
          this.isEdit = true
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
